import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export interface IRadioButtonItem {
  name: string;
  value: string;
  disable: boolean;
}
@Component({
  selector: "shared-funds",
  templateUrl: "./funds.component.html",
  styleUrls: ["./funds.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FundsComponent),
      multi: true,
    },
  ],
})
export class FundsComponent {
  @Input("fundsTitle") title: string;
  @Input("A") fundAdisable: boolean = false;
  @Input("B") fundBdisable: boolean = false;
  @Input("C") fundCdisable: boolean = false;
  @Input("D") fundDdisable: boolean = false;
  @Input("E") fundEdisable: boolean = false;
  @Input() alert: string;

  public disabled: boolean = false;
  public items: IRadioButtonItem[] = [];

  constructor() { }

  ngOnInit() {
    this.items = [
      {
        name: "A",
        value: "A",
        disable: this.fundAdisable,
      },
      {
        name: "B",
        value: "B",
        disable: this.fundBdisable,
      },
      {
        name: "C",
        value: "C",
        disable: this.fundCdisable,
      },
      {
        name: "D",
        value: "D",
        disable: this.fundDdisable,
      },
      {
        name: "E",
        value: "E",
        disable: this.fundEdisable,
      },
    ];
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  private val: string;

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value(): string {
    return this.val;
  }

  setValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
