import { NgModule } from '@angular/core';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser/dist/app/PublicClientApplication';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@NgModule({
  declarations: [],
  imports: [
    MsalModule
  ],
  providers: [
    MsalService,
    AuthenticationService,
    {
      provide: MSAL_INSTANCE,
      useFactory: (): IPublicClientApplication => {
        return new PublicClientApplication({
          auth: {
            clientId: environment.SAML_CONFIG.clientId,
            authority: environment.SAML_CONFIG.authority,
            redirectUri: environment.SAML_CONFIG.redirectUri,
          }
        })
      }
    }
  ]
})
export class Office365Module { }
