import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./components/header/header.component";
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatStepperModule,
  MatToolbarModule,
  MatTooltipModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MomentDateModule } from "@angular/material-moment-adapter";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RightDrawerService } from "./services/right-drawer/right-drawer.service";
import { RouterModule } from "@angular/router";
import { TemplateComponent } from "./template/template.component";
import { CardComponent } from "./components/card/card.component";
import { BeneficiaryComponent } from "./components/beneficiary/beneficiary.component";
import { IncomeComponent } from "./components/income/income.component";
import { LineChartComponent } from "./components/line-chart/line-chart.component";
import { StepperComponent } from "./components/stepper/stepper.component";
import { ClpFormatPipe } from "./pipes/clp-format.pipe";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FundsComponent } from "./components/funds/funds.component";
import { ChipComponent } from "./components/chip/chip.component";
import { ObfusEmailPipe } from "./pipes/obfus-email.pipe";
import { AlertComponent } from "./components/alert/alert.component";
import { ChartsModule, ThemeService } from "ng2-charts";
import { PayCurveComponent } from "./components/pay-curve/pay-curve.component";
import { PageNotFoundComponent } from "./errors/page-not-found/page-not-found.component";
import { FormatRutDirective } from "./directives/format-rut.directive";
import { FormatMoneyDirective } from "./directives/format-money.directive";
import { ClientNotFoundComponent } from './errors/client-not-found/client-not-found.component';
import { VoucherBarChartComponent } from "./components/voucher-barchart/voucher-barchart.component";
import { ClientService } from "./services/client/client.service";
import { BffService } from "./services/bff/bff.service";
import { LoginComponent } from './errors/login/login.component';
import { MatExpansionModule } from '@angular/material';

const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: "l, LTS",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    TemplateComponent,
    CardComponent,
    BeneficiaryComponent,
    IncomeComponent,
    LineChartComponent,
    StepperComponent,
    ClpFormatPipe,
    BreadcrumbComponent,
    FundsComponent,
    ChipComponent,
    ObfusEmailPipe,
    AlertComponent,
    PayCurveComponent,
    PageNotFoundComponent,
    FormatRutDirective,
    FormatMoneyDirective,
    ClientNotFoundComponent,
    VoucherBarChartComponent,
    LoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // Materials
    MatToolbarModule,
    MatSidenavModule,
    MatBadgeModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatChipsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTooltipModule,
    ChartsModule,
    MatExpansionModule
  ],
  exports: [
    HeaderComponent,
    SidenavComponent,
    CardComponent,
    MatButtonModule,
    LineChartComponent,
    BeneficiaryComponent,
    IncomeComponent,
    BreadcrumbComponent,
    StepperComponent,
    ChipComponent,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FundsComponent,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    BeneficiaryComponent,
    MatIconModule,
    MatTooltipModule,
    ClpFormatPipe,
    ObfusEmailPipe,
    AlertComponent,
    PayCurveComponent,
    FormatRutDirective,
    FormatMoneyDirective,
    VoucherBarChartComponent,
    MatExpansionModule
  ],
  providers: [
    RightDrawerService,
    ThemeService,
    ClientService,
    BffService,
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class SharedModule {}
