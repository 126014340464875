import { Injectable } from "@angular/core";
import { StepStatus } from "src/app/shared/enums/StepStatus";

export interface IStep {
  status: StepStatus;
  title: string;
  path: string;
  sessionStorage: string;
}

@Injectable({
  providedIn: "root",
})
export class StepsService {
  private steps: IStep[] = [
    {
      status: StepStatus.Pending,
      title: "Saldos",
      path: "/app/simulate/budget",
      sessionStorage: "step_1"
    },
    {
      status: StepStatus.Pending,
      title: "Estrategia de Inversión",
      path: "/app/simulate/investment",
      sessionStorage: "step_2"
    },
    {
      status: StepStatus.Pending,
      title: "Rentas",
      path: "/app/simulate/income",
      sessionStorage: "step_3"
    },
    {
      status: StepStatus.Pending,
      title: "Beneficiarios",
      path: "/app/simulate/beneficiary",
      sessionStorage: "step_4"
    },
    {
      status: StepStatus.Pending,
      title: "Modalidad de Pensión",
      path: "/app/simulate/pension",
      sessionStorage: "step_5"
    },
    {
      status: StepStatus.Pending,
      title: "Datos Cliente",
      path: "/app/simulate/client",
      sessionStorage: "step_6"
    },
  ];

  getSteps(): IStep[] {
    return this.steps;
  }

  constructor() {}
}
