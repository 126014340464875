import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

export interface IChartElement {
  id: string;
  text: string;
  value: number;
  color: string;
}

@Component({
  selector: "shared-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() CCO: number = 0; // Cuenta Obligatoria
  @Input() CDC: number = 0; // Depositos Convenidos
  @Input() bonusBalance: number = 0; // Bono de reconocimiento
  @Input() CCV: number = 0; // APV
  @Input() CAV: number = 0; // Cuenta 2
  @Input() CVC: number = 0; // Contribuciones Voluntarias Colectivas
  @Input() CAF: number = 0; // Afiliado
  @Input() resume: boolean = true;
  @Input() padding: number = 24;

  public chartElements: IChartElement[] = [
    {
      id: "CCO",
      text: "Cuenta obligatoria",
      value: 0,
      color: "#E0A739",
    },
    {
      id: "CDC",
      text: "Depositos convenidos",
      value: 0,
      color: "#00AEC7",
    },
    {
      id: "bonusBalance",
      text: "Bono de reconocimiento",
      value: 0,
      color: "#f18383",
    },
    {
      id: "CCV",
      text: "APV",
      value: 0,
      color: "#0049B8",
    },
    {
      id: "CAV",
      text: "Cuenta 2",
      value: 0,
      color: "#91B3E7",
    },
    {
      id: "CVC",
      text: "Cotizaciones Voluntarias Colectivas",
      value: 0,
      color: "#728DED",
    },
    {
      id: "CAF",
      text: "Afiliado voluntario",
      value: 0,
      color: "#00D0F5",
    },
  ];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach((x) => {
      if (this.chartElements.some((y) => y.id == x))
        this.chartElements.find((y) => y.id == x).value =
          changes[x].currentValue;
    });
  }
}
