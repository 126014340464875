import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obfusEmail'
})
export class ObfusEmailPipe implements PipeTransform {

  transform(value: any): string {
    if (value) {
      let toObfuscate = value.split("@")[0].split("")
      let nameEmail = toObfuscate.map((v, i)=>i < 1 ? v : "*").join("");
      return `${nameEmail}@${value.split("@")[1]}`;
    }
    return null;
  }
}
