import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AuthenticationGuard } from "./shared/guard/authentication/authentication.guard";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RequestIdInterceptorService } from "./shared/interceptors/requestIdInterceptor/request-id-interceptor.service";
import { BffService } from "./shared/services/bff/bff.service";
import { DataSharedService } from "./shared/services/datashared/datashared.service";
import { Office365Module } from "./shared/services/auth/office365/office365.module";
import { environment } from 'src/environments/environment';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthenticationService } from "./shared/services/auth/authentication/authentication.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    Office365Module,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFireAuthModule
  ],
  providers: [
    AuthenticationGuard,
    BffService,
    AuthenticationService,
    DataSharedService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestIdInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
