import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label } from "ng2-charts";
import { ClpFormatPipe } from "../../pipes/clp-format.pipe";
import './chartjs-rounded-corners';

export class PayCurve {
  public chartLabels: Label[];
  public readonly barChartLegend: boolean = false;
  public readonly chartType: ChartType = 'roundedBar' as ChartType;
  public montosReales: any[];
  public readonly chartOptions: ChartOptions = {

    responsive: true,
    maintainAspectRatio: false,

    barRoundness: 0.1,

    legend: {
      display: true,
      position: "bottom",
      align: "center",
      labels: {
        boxWidth: 15,
        fontSize: 15,
      },
    },
    tooltips: {
      titleAlign: "center",
      titleFontFamily: "Barlow",
      bodyFontFamily: "Barlow",
      titleFontSize: 18,
      bodyFontSize: 18,
      callbacks: {
        title: function (context) {
          let title = context[0].label;
          return title + ' años';
        },
        label: (item) => {

          let valueFormant;
          if (item.index == 0)
            valueFormant = `${this.clpFormatPipe.transform(+item.value - this.rectificaGrafico || 0)}`;
          else
            valueFormant = `${this.clpFormatPipe.transform(+item.value || 0)}`;
          return valueFormant
        },
      },
    },

    scales: {
      xAxes: [
        {
          id: 'edad',
          type: "category",


          gridLines: {
            display: true,
            lineWidth: 0,
            drawBorder: true,
            zeroLineWidth: 2,
            drawTicks: false
          },
          ticks: {
            fontFamily: "Barlow",
            fontSize: 28,
            padding: 10,
            fontColor: "#000000",

            callback: (value) => {
              return value + ` años`;
            }
          },
          scaleLabel: {
            display: false,
            labelString: "Edad",
            fontSize: 14,
            fontFamily: "Barlow",
            fontStyle: "bold"
          },
        },
      ],
      yAxes: [
        {

          id: "y-axis-0",
          stacked: true,
          gridLines: {
            display: true,
            lineWidth: 0,
            drawBorder: true,
            drawTicks: false
          },
          position: "left",
          ticks: {

            display: false,
            beginAtZero: true,
            precision: 0,
            maxTicksLimit: 0,
            fontFamily: "Barlow",
            callback: (value) => {
              return `${this.clpFormatPipe.transform(+value || 0)}`;
            }
          }
        },
      ],
    },

    plugins: {
      datalabels: {
        color: 'white',
        anchor: 'center',
        align: 'end',
        formatter: (value, context) => {
          return this.formateaPension(value, context);
        },
        font: {
          size: 24,

          weight: 'bold'
        }
      },

    }
  };

  public formateaPension(value, context) {

    let valueFormant;
    if (context.dataIndex == 0)
      valueFormant = `${this.clpFormatPipe.transform(+value - this.rectificaGrafico || 0)}`;
    else
      valueFormant = `${this.clpFormatPipe.transform(+value || 0)}`;
    return valueFormant

  }

  public chartDatasets: ChartDataSets[] = [
    {
      data: [],
      barPercentage: 0.8,
      barThickness: 260,
      maxBarThickness: 280,
    }
  ];

  //public barChartPlugins = [pluginDataLabels];

  public chartColors: Color[] = []

  private clpFormatPipe: ClpFormatPipe = new ClpFormatPipe();
  private rectificaGrafico: number = 0;
  private indexMountAgeLegal = 0;
  private indexMountAgeEstimated = 1;

  constructor(labels: any[], data: any[]) {
    this.chartLabels = labels.map(x => x.toString());
    let dataUpdate;
    if (data.length > 1)
      this.chartColors = [
        {
          backgroundColor: ["#1C3A87", "#016531"],
          borderColor: ["#1C3A87", "#016531"],
          pointBackgroundColor: ["#1C3A87", "#016531"],
          pointBorderColor: ["#1C3A87", "#016531"],
          pointHoverBackgroundColor: ["#1C3A87", "#016531"],
          pointHoverBorderColor: ["#1C3A87", "#016531"],
        },
      ];
    else
      this.chartColors = [
        {
          backgroundColor: ["#016531"],
          borderColor: ["#016531"],
          pointBackgroundColor: ["#016531"],
          pointBorderColor: ["#016531"],
          pointHoverBackgroundColor: ["#016531"],
          pointHoverBorderColor: ["#016531"],
        },
      ];
    this.montosReales = data
    if (data.length > 1) {
      this.calculateValueGrafAgeLegal(data)
      dataUpdate = [data[this.indexMountAgeLegal] + this.rectificaGrafico, data[this.indexMountAgeEstimated]]
    }
    else
      dataUpdate = data;

    this.chartDatasets[0].data = dataUpdate.map(x => +x);



  }



  private calculateValueGrafAgeLegal(data: any[]) {
    const obtiene10Porciento = data[this.indexMountAgeEstimated] * 0.1
    const porcentaje = Math.round((data[this.indexMountAgeLegal] / data[this.indexMountAgeEstimated]) * 100)
    // Si el monto de pensión de edad legal es superior al 80%, el valor del grafico no se modifica.

    if (porcentaje >= 80)
      this.rectificaGrafico = 0
    else
      this.rectificaGrafico = obtiene10Porciento * 2
  }
}
