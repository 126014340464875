import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDrawer } from "@angular/material";
import { Office365Service } from "../../services/auth/office365/office365.service";
import { DataSharedService } from "../../services/datashared/datashared.service";
import { RightDrawerService } from "../../services/right-drawer/right-drawer.service";

interface navElement {
  icon: string;
  text: string;
  path: string;
  visible: boolean;
  trackSimulate: boolean;
  trackHome: boolean;
}

@Component({
  selector: "shared-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  @ViewChild("rightDrawer", { static: true }) private rightDrower: MatDrawer;
  @ViewChild("container", { static: true, read: ViewContainerRef })
  container: ViewContainerRef;

  public navElements: navElement[] = [
    {
      icon: "assessment",
      text: "Estimador de pensiones",
      path: "/home",
      visible: true,
      trackSimulate: true,
      trackHome: true,
    },
    {
      icon: "search",
      text: "Buscador de clientes",
      path: "/private",
      visible: true,
      trackSimulate: false,
      trackHome: false,
    },
    {
      icon: "brush",
      text: "Template",
      path: "/template",
      visible: false,
      trackSimulate: false,
      trackHome: false,
    },
  ];

  constructor(
    private rightDrawerService: RightDrawerService,
    private office365Service: Office365Service,
    private readonly dataShareService: DataSharedService
  ) {}

  ngOnInit() {
    this.rightDrawerService.$toogleRightDrawerSource.subscribe(() =>
      this.rightDrower.toggle()
    );

    const entry = this.dataShareService.get("entry", true);

    if (entry) {
      this.office365Service.loggedIn().subscribe((isLogged) => {
        if (entry.type == "executive" && isLogged) {
          const existsAffiliate = this.dataShareService.get("userId", true);
          this.navElements.find(
            (x) => x.text === "Buscador de clientes"
          ).trackHome = !existsAffiliate;
          this.navElements.find(
            (x) => x.text === "Buscador de clientes"
          ).trackSimulate = !existsAffiliate;

          this.navElements.find(
            (x) => x.text === "Estimador de pensiones"
          ).visible = existsAffiliate;
        } else {
          this.navElements.find(
            (x) => x.text === "Buscador de clientes"
          ).trackSimulate = false;
          this.navElements.find(
            (x) => x.text === "Buscador de clientes"
          ).trackHome = false;
          this.navElements.find(
            (x) => x.text === "Estimador de pensiones"
          ).visible = false;
        }
      });
    } else {
      this.navElements.find(
        (x) => x.text === "Estimador de pensiones"
      ).visible = true;
      this.navElements.find((x) => x.text === "Buscador de clientes").visible =
        false;
    }
  }

  get visibleElements(): navElement[] {
    return this.navElements.filter((x) => x.visible);
  }

  get content() {
    return this.rightDrawerService.getContent();
  }

  toggleRightDrawer() {
    this.rightDrower.toggle();
  }

  openEventStart() {
    this.container.createEmbeddedView(this.content);
  }

  closeEventStart() {
    this.container.clear();
  }
}
