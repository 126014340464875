import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClpFormatPipe } from '../pipes/clp-format.pipe';

@Directive({
  selector: 'input[formatMoney]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormatMoneyDirective,
      multi: true,
    },
  ],
})
export class FormatMoneyDirective {
  private onChange: any = () => {};
  private onTouched: any = () => {};

  private clpFormatPipe = new ClpFormatPipe();

  @HostListener("input", ["$event"])
  public onInputBlur(ev: Event) {
    const val = this.elementRef.nativeElement.value;
    this.progagateChange(val);
    this.replaceUI(ev.target);
  }

  constructor(private elementRef: ElementRef) {}

  private writeValue(value: number): void {
    this.elementRef.nativeElement.value = this.moneyFormat(value.toString());
  }

  private registerOnChange(fn: (_ : any) => void): void {
    this.onChange = fn;
  }
  private registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private progagateChange(value: string) {
    this.onChange(+this.moneyClean(value));
    this.onTouched(+this.moneyClean(value));
  }


  private replaceUI(element: any) {
    const htmlInputElement: HTMLInputElement = <HTMLInputElement>element;
    htmlInputElement.value = this.moneyFormat(htmlInputElement.value) || "";
  }

  private moneyClean(value: string) {
    let money = "";
    if (value) money = value.replace(/[^0-9]+/g, "").toUpperCase();
    return money;
  }

  private moneyFormat(value) {
    let money = this.moneyClean(value);
    return this.clpFormatPipe.transform(+money, false);
  }
}
