import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError, of, Observable } from "rxjs";
import { timeout, retry, catchError, switchMap, map, tap } from "rxjs/operators";
import { ESTIMATE_REQUEST, ESTPEN_BASIC } from "src/app/advanced/common/constants/constants";
import {
  REQUEST_TIME_FOR_TIME_OUT_REQUEST,
  REQUEST_RETRY,
} from "../../constants/http.constant";
import { UserModel } from "../../models/user.model";
import { ValueUFResult } from "../../models/value-uf.model";
import { BffService } from "../bff/bff.service";
import { DataSharedService } from "../datashared/datashared.service";
import { ESTPEN_ADVANCED } from '../../../advanced/common/constants/constants';
import * as _ from 'lodash';

@Injectable({
  providedIn: "root",
})
export class ClientService {
  constructor(
    private readonly _bff: BffService,
    private readonly _dataShared: DataSharedService,
    private readonly _router: Router
  ) {}

  public get userId(): string {
    const uid = this._dataShared.get("userId", true) || undefined;
    return uid ? uid : this._router.navigate(["error"]);
  }

  private setData(type: string, payload: any) {
    this._dataShared.set(type, payload, true);
  }

  getData(type: string): Observable<any> {
    const sharedDataValue = this._dataShared.get(type, true);
    if (!sharedDataValue) {
      this._router.navigate(["error"]);
      return;
    }
    return of(sharedDataValue);
  }

  public loadClient(): Observable<UserModel> {
    this._dataShared.set('canal', ESTPEN_BASIC);
    return this._bff.getClientData(this.userId).pipe(
      timeout(REQUEST_TIME_FOR_TIME_OUT_REQUEST),
      retry(REQUEST_RETRY),
      catchError((e) => throwError(e)),
      switchMap((f) => of(f)),
      map((data: UserModel) => {
        if (!_.isEmpty(data)) {
          const { beneficiaries }: any = data;
          this.setData("client", data);
          this.setBeneficiaries(beneficiaries);
        }
        return data;
      })
    );
  }

  public getValueUf(): Observable<ValueUFResult> {
    return this._bff.getValueUf().pipe(
      timeout(REQUEST_TIME_FOR_TIME_OUT_REQUEST),
      retry(REQUEST_RETRY),
      catchError((e) => throwError(e)),
      switchMap((f) => of(f)),
      map((data: ValueUFResult) => {
        return data;
      }),
      
    );
  }

  private setBeneficiaries(beneficiaries: any) {
    beneficiaries = beneficiaries.map((v) => {
      let bday = v.birthDate.split("/");
      return {
        name: `${v.name} ${v.firstSurname} ${v.secondSurname}`,
        birth: `${bday[2]}-${bday[0]}-${bday[1]}`,
        gender: v.gender.replace(/\s+/g, ""),
        relationship: v.resumeRelationship,
        relationshipFront: v.resumeRelationshipFront,
        disability: v.handicapped == true ? "parcial" : "sano",
      };
    });
    this.setData("beneficiaries", beneficiaries);
  }

  public checkSimulations(): Observable<any> {
    this._dataShared.set('canal', ESTPEN_BASIC);
    return this._bff.getSimulation(this.userId).pipe(
      timeout(REQUEST_TIME_FOR_TIME_OUT_REQUEST),
      retry(REQUEST_RETRY),
      catchError((e) => throwError(e)),
      switchMap((f) => of(f)),
      map((data) => {
        if (!_.isEmpty(data)) {
          const arrayReverse: [] = <[]>data["simulaciones"].reverse();
          this.setData("simulations", arrayReverse);
          return arrayReverse;
        } else {
          this.setData("simulations", []);
        }
        return data;
      })
    );
  }

  public deleteSharedData() {
    this._dataShared.delete("userId");
    this._dataShared.delete("client");
    this._dataShared.delete("email");
    this._dataShared.delete("beneficiaries");
    this._dataShared.delete("simulations");
    this._dataShared.delete("valueUf");
    this._dataShared.delete("canal");
    this.deleteSimulationSharedData();
  }

  public deleteSimulationSharedData() {
    this._dataShared.delete("steps");
    this._dataShared.delete(ESTIMATE_REQUEST);
  }
}
