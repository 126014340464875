import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { ClpFormatPipe } from "../../pipes/clp-format.pipe";

export class PayCurve {
  public chartLabels: Label[];
  public readonly barChartLegend: boolean = false;
  public readonly chartType: ChartType = "bar";
  public readonly chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 0,
    legend: {
      display: true,
      position: "bottom",
      align: "center",
      labels: {
        boxWidth: 15,
        fontSize: 12,
      },
    },
    tooltips: {
      titleAlign: "center",
      titleFontFamily: "Barlow",
      bodyFontFamily: "Barlow",
      callbacks: {
        label: (item) => {
          return this.clpFormatPipe.transform(+item.value || 0);
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontFamily: "Barlow",
          },
          scaleLabel: {
            display: false,
            labelString: "Edad",
            fontSize: 14,
            fontFamily: "Barlow",
            fontStyle: "bold"
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          stacked: false,
          gridLines: {
            display: true,
            lineWidth: 1
          },
          position: "left",
          ticks: {
            beginAtZero: true,
            precision: 0,
            fontFamily: "Barlow",
            callback: (value) => {
              return `${this.clpFormatPipe.transform(+value || 0)}`;
            }
          },
          scaleLabel: {
            display: false,
            labelString: "Pagos (miles de pesos)",
            fontSize: 14,
            fontFamily: "Barlow",
            fontStyle: "bold"
          },
        },
      ],
    },
  };

  public chartDatasets: ChartDataSets[] = [
    {
      data: [],
      label: "Curva de Pagos",
      yAxisID: "y-axis-0",
      type: "bar",
      fill: false,
      borderWidth: 1.5
    }
  ];

  public chartColors: Color[] = [
    {
      backgroundColor: "#5971C0",
      borderColor: "#5971C0",
      pointBackgroundColor: "#5971C0",
      pointBorderColor: "#5971C0",
      pointHoverBackgroundColor: "#5971C0",
      pointHoverBorderColor: "#5971C0",
    },
  ];

  private clpFormatPipe: ClpFormatPipe = new ClpFormatPipe();

  constructor(labels: any[], data: any[]) {
    this.chartLabels = labels.map(x => x.toString());
    this.chartDatasets[0].data = data.map(x => +x);
  }
}
