import { ICondition } from "../interfaces/condition.interface";
import { IProfitability } from "../interfaces/profitability";

export const COT_VOL_MENSUAL = "cotVolMensual";
export const DEPOSIT_CONVE_UNICO = "depoConveUnico";
export const DEPOSIT_CONVE_MENSUAL = "depoConveMensual";
export const EDAD = "edad";
export const MONTO = "monto";
export const ESTIMATE_REQUEST = "estimateRequest";
export const ESTIMATE_REQUEST_ADVANCED_LEGAL_AGE = "estimateRequestAdvancedLegalAge";
export const ESTIMATE_REQUEST_ADVANCED_DESIRED = "estimateRequestAdvancedDesired";
export const VOLUNTARY_BALANCE = "voluntaryBalance"
export const EDAD_MAX = 110;
export const GENDER_FEMALE = "F";
export const FEMALE_LEGAL_AGE = 60;
export const MALE_LEGAL_AGE = 65;
export const RETIRO_PROGRAMADO = "Retiro Programado";
export const CLIENT_DATA = "client";
export const ESTPEN_ADVANCED= 'ESTPEN-ADVANCED';
export const ESTPEN_BASIC= 'ESTPEN-BASIC';
export const ENTRY_EXECUTIVE= 'EJECUTIVO';
export const ENTRY_CLIENT= 'AFILIADO';
export const SUM_AGE_ESTIMATE = 2;

export const DESCRIPTION_INFO = {
    [COT_VOL_MENSUAL]: 'El Ahorro Previsional Voluntario (APV) es un ahorro adicional al 10% obligatorio, el cual permite mejorar el monto de tu pensión.',
    [DEPOSIT_CONVE_UNICO]: 'Es un depósito independiente que tiene por objetivo incrementar el capital requerido para anticipar o aumentar el monto de la pensión.',
    [DEPOSIT_CONVE_MENSUAL]: 'Son depósitos independientes de la Cotización Obligatoria y Voluntaria, que el trabajador ha convenido con su empleador con el único objetivo de incrementar el capital.'
};

export const FIELDS_FORM = {
    [EDAD]:
        [
            {
                id: 'edad'
            },
            {
                id: 'cotVolMensual'
            },
            {
                id: 'UFcotVolMensual'
            },
            {
                id: 'depoConveUnico'
            },
            {
                id: 'UFdepoConveUnico'
            },
            {
                id: 'depoConveMensual'
            },
            {
                id: 'UFdepoConveMensual'
            }

        ],
    [MONTO]: [
        {
            id: 'monto'
        },
        {
            id: 'cotVolMensual'
        },
        {
            id: 'UFcotVolMensual'
        },
        {
            id: 'depoConveUnico'
        },
        {
            id: 'UFdepoConveUnico'
        },
        {
            id: 'depoConveMensual'
        },
        {
            id: 'UFdepoConveMensual'
        }

    ]
}

export const TITLE_TEXT = {
    [EDAD]: '¿A qué edad quieres pensionarte?',
    [MONTO]: '¿Con qué monto quieres pensionarte?'
}

export const TITLE_ESTIMADOR = {
    [EDAD]: 'Calcula tu edad de pensión según el monto mensual que esperas recibir',
    [MONTO]: 'Calcula el monto de tu pensión según la edad que esperas pensionarte'
}

export const SUBTITLE_ESTIMADOR = {
    [EDAD]: 'Esta simulación se realiza según el monto deseado de pensión y aportes voluntarios que ingreses.',
    [MONTO]: 'Esta simulación se realiza según tu edad deseada de pensión y aportes voluntarios que ingreses.'
}

export const TITLE_RESULT_ESTIMATOR_VOUCHER = {
    [EDAD]: 'Resultado de tu estimación',
    [MONTO]: 'Resultado de tu estimación'
}

/********************** 
 * RUTAS
 * **********************+*/

export const ADVANCED = 'advanced';
export const HOME = 'home';
export const SIMULATE = 'simulate';
export const COMPROBANTE = 'comprobante';
export const CONDITION = 'condition';
export const PRIVATE = 'private';
export const APP = 'app';
export const ERROR = 'error';
export const CLIENT = 'client';
export const EXTERNAL = 'external';
export const GENERIC_ERROR = 'generic_error';

/********************** 
 * ENUMERACION
 * **********************+*/

export enum ParamsId {
    true,
    false
}


/********************** 
 * CONDITION VOUCHER
 * **********************+*/


export const ListConditionDescription = [
    {
        type: "monto",
        title: "La estimación de pensión responde a tu situación particular en la fecha de simulación.",
        description: " La información registrada podría tener variaciones en el futuro (renta imponible, saldos, aportes, rentabilidad, beneficiarios legales, tablas de mortalidad, tasa de interés, etc.). Por lo anterior, una nueva estimación de pensión o trámite de pensión en AFP Capital podría generar distintos resultados, por lo que tu estimación de pensión es una simulación que no confiere derecho alguno al afiliado."
    },
    {
        type: "monto",
        title: "Con objeto de obtener resultados definitivos,",
        description: " deberás iniciar tu trámite de pensión en cualquiera de nuestras sucursales y esperar el certificado de ofertas emitido por el SCOMP (Sistema de Consultas y Ofertas de Montos de Pensión)."
    },
    {
        type: "monto",
        title: "Para determinar el Capital Necesario Unitario de pensión,",
        description: " esta simulación utiliza tablas de mortalidad vigentes y tasa de interés, la que es calculada e informada por la Superintendencia de Pensiones."
    },
    {
        type: "monto",
        title: "La rentabilidad real utilizada para proyectar sus saldos o aportes",
        description: " tiene relación a cada Multifondo, la cual exponemos a continuación:"
    },
    {
        type: "monto",
        title: "La simulación calcula una Renta Vitalicia inmediata sin condiciones especiales de cobertura.",
        description: " La tasa de interés implícita de la Renta Vitalicia es de un 3,23% en base a lo informado por la Superintendencia de Pensiones."
    },
    {
        type: "monto",
        title: "Si el número de años que quedan para pensionarse es menor a 20,",
        description: " la tasa de renta vitalicia proyectada corresponderá a un valor entre la tasa de renta vitalicia proyectada y la tasa de renta vitalicia de vejez vigente al momento del cálculo de la proyección."
    },
    {
        type: "monto",
        title: "En caso de simular sin beneficiarios y elegir no realizar como soltero,",
        description: " la simulación efectuada incorpora un beneficiario por defecto, de acuerdo a las siguientes reglas: Si el afiliado es de sexo femenino, se incorpora un cónyuge de sexo masculino, con una fecha de nacimiento 2 años mayor a la causante."
    },
    {
        type: "monto",
        title: "Para clientes que están por sobre la edad legal de jubilación,",
        description: " la edad que se considerará para la jubilación será de dos años más que su edad actual. Si el afiliado es de sexo masculino, se incorpora un cónyuge de sexo femenino, con una fecha de nacimiento 2 años menor al causante. No obstante, a la incorporación de un beneficiario por defecto, el usuario puede modificar dicho parámetro (incorporar o eliminar beneficiarios) en la sección \"Ver Beneficiarios\"."
    },
    {
        type: "monto",
        title: "Para mujeres cuya edad sea mayor a 57 años y hombres cuya edad sea mayor a 62 años,",
        description: " la simulación supone una proyección de rentabilidad real de 0% a contar de tal edad. Lo anterior, aplicará a todas sus cuentas menos el saldo por bono de reconocimiento."
    },
    {
        type: "monto",
        title: "Para determinar el Capital Necesario Unitario de pensión,",
        description: " esta simulación utiliza tablas de mortalidad vigentes y tasa de interés, la que es calculada e informada por la Superintendencia de Pensiones."
    },
    {
        type: "monto",
        title: "La renta imponible utilizada para determinar proyección de tus cotizaciones obligatorias",
        description: " es el promedio de las últimas 6 rentas imponibles, considerando el período de tres años anteriores a dicha fecha. Si existieran menos de 6 rentas imponibles en el período, se utilizará el promedio de las existentes. Si no se encuentran rentas imponibles en el período, se utilizará como renta imponible el ingreso mínimo vigente a la fecha de cálculo. No obstante, el usuario puede cambiar este monto."
    },
    {
        type: "monto",
        title: "Sugerimos ingresar el promedio de renta imponible de tus últimos 10 años",
        description: " con objeto que el cálculo utilice los parámetros cercanos a un trámite de pensión ingresado a través de una sucursal de nuestra AFP Capital."
    },
    {
        type: "monto",
        title: "La simulación asume que la renta imponible",
        description: " se mantiene en el futuro y no existen períodos sin cotizaciones."
    },
    {
        type: "monto",
        title: "El Bono de Reconocimiento se proyecta utilizando una tasa de rentabilidad real",
        description: " del 4% anual hasta la fecha de liquidación del Bono (edad de pensión)."
    }
] as ICondition[];

export const RESULT_VOUCHER_DESCRIPTION = {
    mensajeEdadPension: `A los {{resultadoCalculo.resultadoCalculoEdadDeseada.edadPension}} años tu pensión bruta estimada podría ser de`,
    mensajeEstimacionCalculado: 'Esta simulación puede llegar a tu monto deseado, cotizando voluntariamente $10.000 mensuales, aportando ádemas un deposito convenido único de $6.000.000 y sumando un depósito convenido mensual de $30.000',
    mensajeEstimacionComparacion: 'Bajo la misma situación, a los 60 años, el monto de tu pensión mensual bruta será de UF 25,67 ($841.521).',
    saldoTotalProyectado: 'A los 70 años tu pensión bruta estimada podría ser de.'
};
export enum ControlForm {
    cotVolMensual,
    UfcotVolMensual,
    depoConveUnico,
    UfdepoConveUnico,
    depoConveMensual,
    UfdepoConveMensual
}


/********************** 
 * CONDICION ERROR INPUT
 * **********************+*/
export const FIELD_EMPTY_MONT = "field_empty_mont";
export const FIELD_EMPTY_AGE = "field_empty";
export const EXCEED_MAXIMUM = "exceed_maximum";
export const MINIMUM_LEGAL_AGE = "minimum_legal_age";
export const ALREADY_LEGAL_AGE = "already_legal_age";

export const DESCRIPTION_ERROR = {
    [FIELD_EMPTY_MONT]: 'Ingrese un monto',
    [FIELD_EMPTY_AGE]: 'Ingrese una edad',
    [EXCEED_MAXIMUM]: 'Edad máxima 110 años',
    [MINIMUM_LEGAL_AGE]: 'Debe ser mayor a edad legal',
    [ALREADY_LEGAL_AGE]: 'Calcula con más de dos años'
}

export const MESSAGE_ALERT = {
    [MINIMUM_LEGAL_AGE]: 'La edad legal para la mujer es de 60 años y para los hombres de 65 años.',
    [ALREADY_LEGAL_AGE]: 'En caso que hayas cumplido la edad legal, debes realizar la simulación considerando dos años más de tu edad actual, en tu caso tienes {EdadLegal} años por lo cual deberías comenzar simulando con {EdadLegalEstimada} años.'
}

export const ListProfitability = [
    {
        fondo: "A",
        descripcion: "Más riesgoso",
        rentabilidad: "4,49 %"
    }, {
        fondo: "B",
        descripcion: "Riesgoso",
        rentabilidad: "4,02 %"
    }, {
        fondo: "C",
        descripcion: "Intermedio",
        rentabilidad: "3,38 %"
    }, {
        fondo: "D",
        descripcion: "Conservador",
        rentabilidad: "2,81 %"
    }, {
        fondo: "E",
        descripcion: "Más conservador",
        rentabilidad: "2,17 %"
    },
] as IProfitability[];

/* RELATION SHIP */

export const CONYUGE = 'conyuge';
export const HIJO = 'hijo';
export const PADRES = 'padres';
export const CC = 'CC';
export const PADRE_O_MADRE_NO_MATRIMONIAL = 'padre_o_madre_no_matrimonial';
export const OTRO = 'otro';


export const RELATION_SHIP_OPTIONS = {
    [CONYUGE]: 'Cónyuge',
    [HIJO]: 'Hija o Hijo',
    [PADRES]: 'Madre o Padre',
    [CC]: 'Conviviente Civil',
    [PADRE_O_MADRE_NO_MATRIMONIAL]: 'Padres de hijo de afiliación no matrimonial',
    [OTRO]: 'Otro'
} 