import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ESTIMATE_REQUEST } from 'src/app/advanced/common/constants/constants';
import { DataSharedService } from '../../services/datashared/datashared.service';

@Injectable({
  providedIn: 'root'
})
export class CheckRequestEstimateGuard implements CanActivate {

  constructor(private _router: Router, private readonly _dataShared: DataSharedService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     const estimateRequest = this._dataShared.get(ESTIMATE_REQUEST,true);

     if(estimateRequest.projectedType){
      return true;
     }else{
       this._router.navigate(['/private/executive/seeker']);
       return false;
     }
    
  }
  
}
