import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "shared-beneficiary",
  templateUrl: "./beneficiary.component.html",
  styleUrls: ["./beneficiary.component.scss"],
})
export class BeneficiaryComponent implements OnInit {
  @Input() name: string = "Sin información";
  @Input() relationship: string = "Sin información";
  @Input() action: boolean = false;

  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
