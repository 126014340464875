import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import jwt_decode from "jwt-decode";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../services/auth/authentication/authentication.service";
import { ClientService } from "../../services/client/client.service";

@Injectable({
  providedIn: "root",
})
export class ExternalTokenGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _auth: AuthenticationService,
    private readonly _client: ClientService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { token } = next.queryParams;
    try {
      jwt_decode(token);
      return true;
    } catch (err) {
      this._client.deleteSharedData();
      this._auth.firebaseSignOut();
      this._router.navigate(["error/client"]);
    }
  }
}
