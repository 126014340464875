import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, throwError } from "rxjs";
import { AuthenticationService } from "../../services/auth/authentication/authentication.service";
import jwt_decode from "jwt-decode";
import { DataSharedService } from "../../services/datashared/datashared.service";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { CLIENT_DATA } from "src/app/advanced/common/constants/constants";
import { version } from 'package.json'

@Injectable()
export class RequestIdInterceptorService implements HttpInterceptor {
  constructor(
    private readonly _auth: AuthenticationService,
    private readonly _dataShared: DataSharedService,
    private readonly _router: Router,
    private readonly _firebase: AngularFireAuth
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(
      this.handleAccess(req, next).catch((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this._auth.firebaseSignOut();
          this._router.navigate(["error/client"]);
        }
        return throwError(error).toPromise();
      })
    );
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    try {
      const user = await this._auth.getUser();

      return user
        ? this.getToken(request, next)
        : next.handle(request).toPromise();
    } catch (err) {
      return err;
    }
  }

  async getToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const token = await this._auth.getIdToken();

    if (token) {
      const { user_id } = token
        ? (jwt_decode(token) as any)
        : { user_id: null };
      const clientRut = this._dataShared.get("userId", true) || undefined;
      const entry = this._dataShared.get('entry', true);
      const executive = this._dataShared.get('executive', true);
      const canal = this._dataShared.get('canal');

      const id = clientRut ? clientRut : user_id;

      request = request.clone({
        setHeaders: {
          Authorization: String(token),
          "X-REQUEST-ID": id,
          "rutClient": clientRut,
          "canal": canal,
          "entry": entry && executive ? "EJECUTIVO": "AFILIADO",
          "versionFront": version
        },
      });
    }
    return next.handle(request).toPromise();
  }
}
