import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RightDrawerService {

  private toogleRightDrawer: Subject<void> = new Subject();
  public $toogleRightDrawerSource: Observable<void> = this.toogleRightDrawer.asObservable();
  private contentRef: TemplateRef<any>;

  constructor() { }

  public setContent(content: TemplateRef<any>) {
    this.contentRef = content;
  }

  public getContent(): TemplateRef<any> {
    return this.contentRef;
  }

  public toggle(): void {
    this.toogleRightDrawer.next();
  }
}
