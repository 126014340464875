import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientService } from '../../client/client.service';
import { DataSharedService } from '../../datashared/datashared.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class Office365Service {

  private logginStatusSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private $logginStatus: Observable<boolean> = this.logginStatusSource.asObservable();

  constructor(
    private readonly _msal: MsalService,
    private readonly _router: Router,
    private readonly _dataShared: DataSharedService,
    private readonly _auth: AuthenticationService,
    private readonly _clientService: ClientService
  ) {
    this.logginStatusSource.next(this.isLoggedIn());
  }

  isLoggedIn(): boolean {
    return this._msal.instance.getActiveAccount() != null;
  }

  loggedIn(): Observable<boolean> {
    return this.$logginStatus
  }

  login() {
    this._msal.loginPopup().subscribe((response: AuthenticationResult) => {
      this._dataShared.set('entry', { type: 'executive' }, true);
      this._dataShared.set('executive', response.account.username, true);
      this._auth.executiveLogin(response.idToken).subscribe(() => {
        this._msal.instance.setActiveAccount(response.account);
        this.logginStatusSource.next(true);
        this._router.navigate(['private/executive/seeker']);
      }, () => {
        this._router.navigate(['error/signin']);
      });
    }, () => {
      this._router.navigate(['error/signin']);
    });
  }

  logout() {
    this._msal.logout();
    this._auth.firebaseSignOut();
    this.logginStatusSource.next(false);
    this._clientService.deleteSharedData();
  }


  getName() {
    return this._msal.instance.getActiveAccount().name;
  }

  getUsername() {
    return this._msal.instance.getActiveAccount().username;
  }
}
