export const environment = {
  URL_BFF_API: 'https://estimador-qa.bff.afpcapital.cl',
  SAML_CONFIG: {
    clientId: 'f944109e-7c4a-45fb-9fc2-0b495a5086d5',
    redirectUri: 'https://estimador-qa.afpcapital.cl/private/executive',
    authority: 'https://login.microsoftonline.com/organizations',
  },
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCNTsU3GFdefrSeCvLwIcyibnlXUBln4bI",
    authDomain: "afp-minisitio-priv-qa-2.firebaseapp.com",
    projectId: "afp-minisitio-priv-qa-2",
    storageBucket: "afp-minisitio-priv-qa-2.appspot.com",
    messagingSenderId: "86246718332",
    appId: "1:86246718332:web:545ee0222eef39ac4a42f9",
    measurementId: "G-1F7PZLPN5T"
  },
  production: true
};
