import { Component, OnInit } from "@angular/core";
import { ICurve } from "../interfaces/curve.interface";

@Component({
  selector: "app-template",
  templateUrl: "./template.component.html",
  styleUrls: ["./template.component.scss"],
})
export class TemplateComponent implements OnInit {
  public CAF: number = 0;
  public CAI: number = 0;
  public CAV: number = 0;
  public CCO: number = 0;
  public CCV: number = 0;
  public CDC: number = 0;
  public bonusBalance: number = 0;
  public curve: ICurve = {
    cnu: 192.404159,
    ages: [
      65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
      83,
    ],
    payments: [
      762881, 745793, 742701, 739331, 735651, 731640, 727164, 722310, 717046,
      711334, 705136, 696041, 686212, 675640, 664319, 652234, 636120, 619012,
      600865,
    ],
    projectedPension: "762881",
    projectedBalance: "146781386",
  };
  public activeChips: boolean = false;

  constructor() {}

  ngOnInit() {
    this.randomChartValue();
  }

  randomChartValue() {
    let rest = 100;
    this.CAF = this.getRandom(rest);
    rest -= this.CAF;
    this.CAI = this.getRandom(rest);
    rest -= this.CAI;
    this.CAV = this.getRandom(rest);
    rest -= this.CAV;
    this.CCO = this.getRandom(rest);
    rest -= this.CCO;
    this.CCV = this.getRandom(rest);
    rest -= this.CCV;
    this.CDC = this.getRandom(rest);
    rest -= this.CDC;
    this.bonusBalance = rest;
  }

  getRandom(max: number): number {
    // Retorna un número aleatorio entre 0 (incluido) y max (excluido)
    return Math.floor(Math.random() * max);
  }
}
