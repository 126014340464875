import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}

  sum(arrNumeric: number[]): number {
    return arrNumeric.reduce((a, c) => a + c);
  }

  arrNumbersToPercentage(arr: number[]): number[] {
    let balance = arr.reduce((a, c) => a + c);
    return arr.map((v) => Number(((v * 100) / balance).toFixed(0)));
  }

  calculateAge(birthday: string) {
    const birth: Date = new Date(birthday);
    let ageDifMs = Date.now() - birth.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getFullYear() - 1970);
  }

  transformGender(gender: string) {
    switch (gender) {
      case "Femenino":
        return "F";
      case "Masculino":
        return "M";
      case "F":
        return "Femenino";
      case "M":
        return "Masculino";

      default:
        return "";
    }
  }

  transformCivilStatus(civilStatus: string) {
    switch (civilStatus) {
      case "C":
        return "Casado";
      case "S":
        return "Soltero";

      default:
        return "";
    }
  }

  setChildrensList(beneficiaries) {
    if (beneficiaries == null)
      return { childrenSex: [], childrenAge: [], childrenStatus: [] };

    const childrens = beneficiaries.filter(
      (v) => v.relationship == "hijo" || v.relationship == "hija"
    );
    const childrenSex = childrens.map((v) => v.gender) || [];
    const childrenAge = childrens.map((v) => this.calculateAge(v.birth)) || [];
    const childrenStatus = childrens.map((v) => v.disability) || [];

    return { childrenSex, childrenAge, childrenStatus };
  }

  setOthersList(beneficiaries) {
    if (beneficiaries == null)
      return { otherRelationships: [], otherSex: [], otherAge: [] };

    const others = beneficiaries.filter(
      (v) =>
        v.relationship != "hijo" &&
        v.relationship != "hija" &&
        v.relationship != "conyuge"
    );
    const otherRelationships = others.map((v) => v.relationship) || [];
    const otherSex = others.map((v) => v.gender) || [];
    const otherAge = others.map((v) => this.calculateAge(v.birth)) || [];
    const otherStatus = others.map((v) => v.disability) || [];

    return { otherRelationships, otherSex, otherAge, otherStatus };
  }

  dateValidator(AC: AbstractControl) {
    if (!AC || !AC.value) {
      return { dateVaidator: true };
    }

    let minYear = Number(new Date().getFullYear()) - 100;
    let minDateFormatted = new Date(`${minYear}`);

    if (
      minDateFormatted.getTime() >= new Date(AC.value).getTime() ||
      new Date(AC.value).getTime() > new Date().getTime()
    ) {
      return { dateVaidator: true };
    }

    return null;
  }

  nameValidator(AC: AbstractControl) {
    if (!AC || !AC.value) {
      return { nameValidator: true };
    }

    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (format.test(AC.value)) {
      return { nameValidator: true };
    }

    return null;
  }

  nullValidator(AC: AbstractControl) {
    if (!AC || AC.value === null) {
      return { nullValidator: true };
    }

    return null;
  }

  mailValidator(email: string): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      return true;

    return false;
  }

  cleanRut(rut: string) {
    return rut ? rut.replace(/[^0-9kK]+/g, "").toUpperCase() : null;
  }


  public formatDateYYYYMMDD(date: string, currentSeparator: string, newSeparator: string): string {
    const splittedDate = date.split(currentSeparator);
    const day = splittedDate[0].length === 1 ? '0' + splittedDate[0]: splittedDate[0] ;
    const month = splittedDate[1].length === 1 ? '0' + splittedDate[1]: splittedDate[1];
    const year = splittedDate[2];
    return [year, month, day].join(newSeparator);
  }
}
