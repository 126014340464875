import { Directive, ElementRef, HostListener } from "@angular/core";
import { AbstractControl, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: "input[formatRut]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormatRutDirective,
      multi: true,
    },
  ],
})
export class FormatRutDirective {
  private onChange: any = () => {};
  private onTouched: any = () => {};

  @HostListener("input", ["$event"])
  public onInputBlur(ev: Event) {
    const val = this.elementRef.nativeElement.value;
    this.progagateChange(val);
    this.replaceUI(ev.target);
  }

  constructor(private elementRef: ElementRef) {}

  private writeValue(value: string | number): void {
    if (value)
      this.elementRef.nativeElement.value = this.rutFormat(value.toString());
  }

  private registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  private registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private progagateChange(value: string) {
    this.onChange(this.rutClean(value));
    this.onTouched(this.rutClean(value));
  }

  private replaceUI(element: any) {
    const htmlInputElement: HTMLInputElement = <HTMLInputElement>element;
    htmlInputElement.value = this.rutFormat(htmlInputElement.value) || "";
  }

  private rutClean(value: string) {
    let rut = "";
    if (value) rut = value.replace(/[^0-9kK]+/g, "").toUpperCase();
    return rut;
  }

  private rutFormat(value) {
    let rut = this.rutClean(value);
    if (rut.length <= 3) return rut;
    rut = rut.substr(0, rut.length - 1) + "-" + rut.substr(rut.length - 1);
    let result = rut.slice(-5);
    for (let i = 5; i < rut.length; i += 3)
      result = rut.slice(-3 - i, -i) + "." + result;
    return result;
  }

  public static rutValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const value = control.value;
    if (!value || typeof value !== "string") return null;
    let rut = value.replace(/[^0-9kK]+/g, "").toUpperCase();
    let rutDigits = parseInt(rut.slice(0, -1), 10);
    let m = 0;
    let s = 1;
    while (rutDigits > 0) {
      s = (s + (rutDigits % 10) * (9 - (m++ % 6))) % 11;
      rutDigits = Math.floor(rutDigits / 10);
    }
    let checkDigit = s > 0 ? String(s - 1) : "K";
    return checkDigit === rut.slice(-1) ? null : { invalidRut: true };
  }

  // private rutValidate(value): boolean {
  //   if (!value || typeof value !== "string") return false;
  //   let rut = this.rutClean(value);
  //   let rutDigits = parseInt(rut.slice(0, -1), 10);
  //   let m = 0;
  //   let s = 1;
  //   while (rutDigits > 0) {
  //     s = (s + (rutDigits % 10) * (9 - (m++ % 6))) % 11;
  //     rutDigits = Math.floor(rutDigits / 10);
  //   }
  //   let checkDigit = s > 0 ? String(s - 1) : "K";
  //   return checkDigit === rut.slice(-1);
  // }
}
