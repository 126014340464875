import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthenticationService } from "../../services/auth/authentication/authentication.service";

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private readonly _auth: AuthenticationService,
    private readonly _router: Router,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const { debug, loadRut } = next.queryParams;
    const isDebug: boolean = debug == "1" ? true : false;
    const isLogedIn = await this._auth.getUser();

    if (isLogedIn) {
      return true;
    } else if (isDebug) {
      const customRut: string = loadRut ? loadRut : "";
      // TODO: CREATE A DEBUG MODE WITHOUT TOKEN VALIDATION
      this._router.navigate(["error/client"]);
    } else {
      this._router.navigate(["error/client"]);
    }
  }
}
