import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { Observable, Subject } from "rxjs";
import jwt_decode from "jwt-decode";
import { environment } from "src/environments/environment";
import { DataSharedService } from "../../datashared/datashared.service";
import { resolve } from "url";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

const PATH_BASE_DEV =
  "https://bff-estimador-web-api-dev-6x7adyjl.ue.gateway.dev";

@Injectable()
export class AuthenticationService {
  private http: HttpClient;
  private readonly pathBFF;
  private userIdIndentified: string;

  constructor(
    private readonly _auth: AngularFireAuth,
    private readonly _handler: HttpBackend,
    private readonly _dataShared: DataSharedService,
    private readonly _router: Router
  ) {
    this.http = new HttpClient(_handler);
    this.pathBFF = environment.URL_BFF_API || PATH_BASE_DEV;
  }

  clientLogin(externalToken: string): Observable<any> {
    const tokenDecode: any = jwt_decode(externalToken);
    const uid = tokenDecode && tokenDecode.uid ? tokenDecode.uid : undefined;
    const email =
      tokenDecode && tokenDecode.claims && tokenDecode.claims.email
        ? tokenDecode.claims.email
        : undefined;

    return this.http
      .get(`${this.pathBFF}/client/login/${uid}`, {
        headers: {
          Authorization: `Bearer ${externalToken}`,
        },
      })
      .pipe(
        map((data: any) => {
          this._dataShared.set("userId", uid, true);
          if (email) this._dataShared.set("email", email, true);
          const { token } = data;
          return token;
        })
      );
  }

  executiveLogin(tokenActiveDirectory: string): Observable<any> {
    const tokenDecode: any = jwt_decode(tokenActiveDirectory);
    const uid =
      tokenDecode && tokenDecode.preferred_username
        ? tokenDecode.preferred_username
        : undefined;
    return this.http
      .get(`${this.pathBFF}/executive/login/${uid}`, {
        headers: {
          authorization: tokenActiveDirectory,
        },
      })
      .pipe(
        map((data: any) => {
          const { token } = data;
          this.signInWithCustomToken(token);
          return token;
        })
      );
  }

  public async getUser() {
    return await new Promise((resolve) => {
      this._auth.auth.onAuthStateChanged((user) => {
        resolve(user);
      });
    });
  }

  public async getIdToken(): Promise<string> {
    return await this._auth.auth.currentUser.getIdToken();
  }

  public async signInWithCustomToken(token: string) {
    await this.firebaseSignOut();

    await this._auth.auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(async () => await this._auth.auth.signInWithCustomToken(token))
      .catch((err) => this._router.navigate(['/error/signin']));
  }

  async firebaseSignOut(): Promise<void> {
    await this._auth.auth.signOut().catch(() => {});
  }
}
