import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() type: string = "info";
  @Input() icon: string;
  @Input() placement: string = "left";


  constructor() { }

  ngOnInit() {
  }

}
