import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Office365Service } from '../../services/auth/office365/office365.service';
import { BffService } from '../../services/bff/bff.service';

@Injectable({
  providedIn: 'root'
})
export class ExecutiveGuard implements CanActivate {

  constructor(private _router: Router, private _office365Service: Office365Service) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._office365Service.isLoggedIn()) {
      return true;
    } else {
      this._router.navigate(['private/executive/login']);
      return false;
    }
  }
}
