import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "clpFormat",
})
export class ClpFormatPipe implements PipeTransform {
  transform(
    value: number,
    symbol: boolean = true,
    decimalLength: number = 0,
    chunkDelimiter: string = ".",
    decimalDelimiter: string = ",",
    chunkLength: number = 3
  ): string {
    value = typeof value == 'string' ? +value : value;
    if(value >= 0) {
      let result =
        "\\d(?=(\\d{" +
        chunkLength +
        "})+" +
        (decimalLength > 0 ? "\\D" : "$") +
        ")";
      let num = Math.trunc(value).toFixed(Math.max(0, ~~decimalLength));
      const currencySign = symbol ? "$ " : "";

      return (
        currencySign +
        (decimalDelimiter ? num.replace(".", decimalDelimiter) : num)
          .replace(
            new RegExp(result, "g"),
            "$&" + chunkDelimiter
          )
      );
    }
    return null;
  }
}
