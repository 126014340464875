import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ICurve } from '../../interfaces/curve.interface';
import { PayCurve } from './voucher-barchart';
import { ESTIMATE_REQUEST_ADVANCED_DESIRED, ListProfitability } from '../../../advanced/common/constants/constants';
import { animate, style, transition, trigger } from '@angular/animations';
import { BffService } from '../../services/bff/bff.service';
import { DataSharedService } from '../../services/datashared/datashared.service';

@Component({
  selector: 'voucher-barchart',
  templateUrl: './voucher-barchart.component.html',
  styleUrls: ['./voucher-barchart.component.scss'],
  animations: [
    trigger("myInsertRemoveTrigger", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("800ms ease-in-out", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class VoucherBarChartComponent implements OnInit {
  @Input("curve") curveData: ICurve;
  @Output() calcular: EventEmitter<any> = new EventEmitter();

  public chartHeight: number = 100;

  public curve: PayCurve;
  public selectedValue: string = "";
  public listProfitability = ListProfitability;
  public panelOpenStateProgra: boolean = false;
  public isFlowDiscount: boolean = true;
  public loading: boolean = false;
  public disabledSelectRent: boolean = true

  constructor(private _dataShared: DataSharedService) { }

  ngOnInit() {
  
  }
  ngOnChanges() {
    this.curve = new PayCurve(this.curveData.ages, this.curveData.payments);
    this.loading = false      
  }

  public changeRentabilidad(event) {    
    this.disabledSelectRent = false
    this.selectedValue = event
  }

  send() {
    this.loading = true;
    this.calcular.emit(this.selectedValue);
  }

  public openState() {
    this.panelOpenStateProgra = !this.panelOpenStateProgra;
  }

  public disabledFondo(fondo) {
    const request = this._dataShared.get(ESTIMATE_REQUEST_ADVANCED_DESIRED, true)    
    if (fondo === 'A' && ((request.affiliateGender === 'F' && request.age > 50) || (request.affiliateGender === 'M' && request.age > 55)))
      return true
    else
      return false
  }
}
