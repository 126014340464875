import { Component, Input, OnInit } from '@angular/core';
import { ICurve } from '../../interfaces/curve.interface';
import { PayCurve } from './pay-curve';

@Component({
  selector: 'shared-pay-curve',
  templateUrl: './pay-curve.component.html',
  styleUrls: ['./pay-curve.component.scss']
})
export class PayCurveComponent implements OnInit {
  @Input("curve") curveData: ICurve;

  public chartHeight: number = 100;

  public curve: PayCurve;

  constructor() { }

  ngOnInit() {
    this.curve = new PayCurve(this.curveData.ages, this.curveData.payments);
  }
}
