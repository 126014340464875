import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ClientNotFoundComponent } from "./shared/errors/client-not-found/client-not-found.component";
import { LoginComponent } from "./shared/errors/login/login.component";
import { PageNotFoundComponent } from "./shared/errors/page-not-found/page-not-found.component";
import { AuthenticationGuard } from "./shared/guard/authentication/authentication.guard";
import { ExternalTokenGuard } from "./shared/guard/external-token/external-token.guard";
import { TemplateComponent } from "./shared/template/template.component";
import { ADVANCED, PRIVATE, APP, ERROR, CLIENT, HOME, EXTERNAL } from './advanced/common/constants/constants';
import { ExecutiveGuard } from "./shared/guard/authentication/executive.guard";
import { CheckRequestEstimateGuard } from "./shared/guard/check-request-estimate/check-request-estimate.guard";

const routes: Routes = [
  { path: "", redirectTo: HOME, pathMatch: "full" },
  {
    path: HOME,
    canActivate: [
      AuthenticationGuard,
    ],
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: APP,
    data: {
      breadcrumb: "Estimador de pensiones",
    },
    loadChildren: () =>
      import("./simulate/simulate.module").then((m) => m.SimulateModule),
  },
  {
    path: PRIVATE,
    data: {
      breadcrumb: "Portal privado",
    },
    loadChildren: () =>
      import("./executive/executive.module").then((m) => m.ExecutiveModule),
  },
  {
    path: EXTERNAL,
    canActivate: [ExternalTokenGuard],
    loadChildren: () =>
      import("./external/external.module").then((m) => m.ExternalModule),
  },
  // { path: 'template', component: TemplateComponent },
  {
    path: ADVANCED,
    canActivate: [
      ExecutiveGuard,
      CheckRequestEstimateGuard
    ],
    loadChildren: () =>
      import("./advanced/advanced.module").then((m) => m.AdvancedModule),
  },
  {
    path: ERROR,
    children: [
      {
        path: "",
        redirectTo: CLIENT,
        pathMatch: "full",
      },
      {
        path: CLIENT,
        component: ClientNotFoundComponent,
      },
      {
        path: "signin",
        component: LoginComponent,
      },
    ],
  },
  { path: "**", pathMatch: "full", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
