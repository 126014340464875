import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})

export class CardComponent implements OnInit {

  @Input("cardTitle") title: string;
  @Input() subtitle: string;
  @Input() placement: string = "center";
  @Input() padding: number = 16;

  constructor() { }

  ngOnInit() { }
}
