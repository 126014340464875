import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSharedService {

  public set(key: string, data: any, parse = false) {
    if (parse)
      sessionStorage.setItem(key, JSON.stringify(data));
    else
    sessionStorage.setItem(key, data);
  }

  public get(key: string, parse = false) {
    if (parse)
      return JSON.parse(sessionStorage.getItem(key));
    else
      return sessionStorage.getItem(key);
  }

  public getList() {
    return sessionStorage;
  }

  public delete(key: string) {
    sessionStorage.removeItem(key);
  }
}
