import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  IEstimateRequest,
  IEstimateRetirementRequest,
} from "../../interfaces/estimateRequest";
import { UtilsService } from "../../utils/utils.service";
import { forkJoin } from 'rxjs';
import { DataSharedService } from "../datashared/datashared.service";
import * as moment from 'moment';
import { RETIRO_PROGRAMADO } from '../../../advanced/common/constants/constants';

const PATH_BASE_DEV =
  "https://bff-estimador-web-api-dev-6x7adyjl.ue.gateway.dev";

@Injectable({
  providedIn: "root",
})
export class BffService {

  private readonly pathBaseBff;

  constructor(private readonly _http: HttpClient, private utilsService: UtilsService, private readonly _dataShared: DataSharedService,) {
    this.pathBaseBff = environment.URL_BFF_API || PATH_BASE_DEV;
  }

  getClientData(uid: string) {
    return this._http.get(`${this.pathBaseBff}/client/${uid}`);
  }

  getSimulation(uid: string) {
    return this._http.get(`${this.pathBaseBff}/client/${uid}/simulations`);
  }

  postPension(estimateRequest: IEstimateRequest) {
    return this._http.post(
      `${this.pathBaseBff}/estimator/pension`,
      estimateRequest,
    );
  }

  postPensionAll(estimateRequestSimulate: IEstimateRequest, estimateRequestLegalAge: IEstimateRequest) {
    let responseSimulate = this._http.post(
      `${this.pathBaseBff}/estimator/pension`,
      estimateRequestSimulate
    );

    let responseLegalAge = this._http.post(
      `${this.pathBaseBff}/estimator/pension`,
      estimateRequestLegalAge
    );

    return forkJoin([responseSimulate, responseLegalAge]);
  }

  postRetirement(estimateRequest: IEstimateRetirementRequest) {
    return this._http.post(
      `${this.pathBaseBff}/estimator/retirement`,
      estimateRequest
    );
  }

  postRetirementAll(estimateRequestSimulate: IEstimateRetirementRequest, estimateRequestLegalAge: IEstimateRetirementRequest) {

    let responseSimulate = this._http.post(
      `${this.pathBaseBff}/estimator/retirement`,
      estimateRequestSimulate
    );

    let responseLegalAge = this._http.post(
      `${this.pathBaseBff}/estimator/retirement`,
      estimateRequestLegalAge
    );

    return forkJoin([responseSimulate, responseLegalAge]);
  }

  postRetirementAge(estimateRequest: IEstimateRetirementRequest) {
    return this._http.post(
      `${this.pathBaseBff}/estimator/retirement/age`,
      estimateRequest
    );
  }

  postPensiontAge(estimateRequest: IEstimateRetirementRequest) {
    return this._http.post(
      `${this.pathBaseBff}/estimator/pension/age`,
      estimateRequest
    );
  }

  postRetirementByProduct(estimateRequest: IEstimateRetirementRequest, projectedType: string, estimatedAge: boolean) {

    if (projectedType == RETIRO_PROGRAMADO) {
      if (!estimatedAge)
        return this._http.post(
          `${this.pathBaseBff}/estimator/retirement`,
          estimateRequest
        );
      else
        return this._http.post(
          `${this.pathBaseBff}/estimator/retirement/age`,
          estimateRequest
        );
    }
    else {
      if (!estimatedAge)
        return this._http.post(
          `${this.pathBaseBff}/estimator/pension`,
          estimateRequest,
        );
      else
        return this._http.post(
          `${this.pathBaseBff}/estimator/pension/age`,
          estimateRequest,
        );
    }
  }

  getValueUf() {
    const date = moment().format('DD/MM/YYYY').toString();
    const dateFormated: string = this.utilsService.formatDateYYYYMMDD(date, '/', '-');
    return this._http.get(`${this.pathBaseBff}/estimator/uf/${dateFormated}/${dateFormated}`);
  }
}
