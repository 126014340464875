import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "shared-income",
  templateUrl: "./income.component.html",
  styleUrls: ["./income.component.scss"],
})
export class IncomeComponent implements OnInit {
  @Input() range: string = "Sin información";
  @Input() date: Date = new Date();
  @Input() amount: number = 0;

  constructor() {}

  ngOnInit() {}
}
