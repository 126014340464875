import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() color: string = "#0049B8";

  constructor() { }

  ngOnInit() {
  }

}
