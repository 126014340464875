import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { StepStatus } from "src/app/shared/enums/StepStatus";
import { IStep, StepsService } from "../../services/steps/steps.service";

@Component({
  selector: "shared-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent implements OnInit {
  @Input("step") currentStep: number = 0;
  @Input("customTitle") customResponsiveTitle: string;
  @Input("customDescription") description: TemplateRef<any>;

  public steps: IStep[] = [];

  constructor(private stepsService: StepsService) {
    this.steps = stepsService.getSteps();
  }

  public get Status(): typeof StepStatus {
    return StepStatus;
  }

  ngOnInit() {
    this.steps.forEach((step, index) => {
      if (index + 1 < this.currentStep) step.status = StepStatus.Complete;
      else if (index + 1 == this.currentStep) step.status = StepStatus.Current;
      else step.status = StepStatus.Pending;
    });
  }

  getCurrentTitle(): string {
    return this.steps.some((p) => p.status == StepStatus.Current)
      ? this.steps.find((p) => p.status == StepStatus.Current).title
      : "";
  }

  public getCurrentStep(): number {
    const step: any = this.steps.findIndex(
      (p) => p.status == StepStatus.Current
    );
    return step >= 0 ? step + 1 : 0;
  }

  public getPercentage() {
    let totalSteps = this.steps.length;
    let porcentaje = Math.floor((this.getCurrentStep() * 100) / totalSteps);

    let r = 35;
    var c = Math.floor(Math.PI * (r * 2));

    porcentaje = ((100 - porcentaje) / 100) * c;
    return porcentaje;
  }
}
