import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { isNullOrUndefined } from "util";

interface IBreadcrumnbItem {
  label: string;
  url: string;
}

@Component({
  selector: "shared-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  public items: IBreadcrumnbItem[] = [];

  get breadcrumbItems(): IBreadcrumnbItem[] {
    return this.items.slice(0, this.items.length - 1)
  }

  private subs: Subscription = new Subscription();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.createBreadcrumbs(this.activatedRoute.root);
    this.subs.add(
      this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.createBreadcrumbs(this.activatedRoute.root);
      })
    )
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: IBreadcrumnbItem[] = []
  ) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      this.items = breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join("/");
      if (routeURL !== "") {
        url += `/${routeURL}`;
      }

      const label =
        child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!isNullOrUndefined(label)) {
        breadcrumbs.push({ label, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
